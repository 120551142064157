import axios from "axios";
import { message } from "ant-design-vue";
const env = process.env;
import router from "@/router/index";

// console.log('env.NODE_ENV',env.NODE_ENV)
const baseUrl = env.VUE_APP_API;
// if (env.NODE_ENV === "development") {
//   baseUrl = `http://192.168.3.81:8080`;
// } else {
// baseUrl 
// }
// console.log(baseUrl, "baseUrlbaseUrlbaseUrl");

axios.defaults.baseURL = baseUrl;

declare module "axios" {
  interface AxiosResponse {
    msg: "";
    code: null;
  }
  export function create(config?: AxiosRequestConfig): AxiosInstance;
}

axios.defaults.withCredentials = false;
// +

const request = axios.create({
  timeout: 40000, // 请求超时时间(毫秒)
  // withCredentials: true,// 异步请求携带cookie
  headers: {
    "X-Requested-With": "XMLHttpRequest",
  },
});

// 设置post数据格式
request.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded;charset=utf-8";

// request拦截器
request.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      //添加请求头
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// response 拦截器
request.interceptors.response.use(
  (response) => {
    const { data } = response;
    const {
      data: { code, msg }, config: { headers:
        { notExpand } }
    } = response;

    if (response && !code && !notExpand) {
      return data;
    }
    if (response && !code && notExpand) {
      return response;


    }
    if (code == 0) return data;
    message.error(msg);
    if (code === "A401011"||code === "A401000") {
      console.log(router, 'router')
      router.push({ name: "login" });
    }
    return Promise.reject(response);
  },
  (error) => {
    console.log(1);
    const {
      response: {
        data: { message: msg, code },
      },
    } = error;
    // 对响应错误做点什么
    console.log(code, 'code')
    message.error(msg);
    if (code === "A401011"||code === "A401000") {
      console.log(router, 'router')
      router.push({ name: "login" });
    }


    return Promise.reject(error);
  }
);
export default request;
