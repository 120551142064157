<template>
  <div class="ConfigcurrencyView">
    <div class="ConfigcurrencyView-search">
      <a-button type="primary" style="margin-right: 24px" @click="toAdd()"
        >新增</a-button
      >
    </div>
    <div class="ConfigcurrencyView-content">
      <a-table :columns="columns" :data-source="list" :pagination="false">
        <template #bodyCell="{ column, record }">
          <template v-if="column.dataIndex === 'action'">
            <a-button type="link" @click="editThis(record)">编辑</a-button>
            <a-button danger type="link" @click="cancelThis(record)"
              >删除</a-button
            >
          </template>
        </template>
      </a-table>
      
      <PaginationPage
        :pager="pager"
        @emitPage="getPage"
        style="margin-top: 20px"
      />
    </div>

    <a-modal
      v-model:visible="isShow"
      @ok="handleOk"
      @cancel="cancelModal"
      :closable="false"
      :width="800"
    >
      <div class="popBox">
        <div class="popBox-title">
          {{ formState.type == "add" ? "新增币种" : "编辑币种" }}
        </div>
        <a-form
          :model="formState"
          :label-col="labelCol"
          ref="formRef"
          :wrapper-col="wrapperCol"
          style="margin-top: 32px"
        >
          <a-form-item
            label="代码"
            name="code"
            :rules="[{ required: true, message: '请输入代码!' }]"
          >
            <a-input
              :disabled="formState.type == 'add' ? false : true"
              v-model:value="formState.code"
              placeholder="请输入代码"
            />
          </a-form-item>
          <a-form-item
            label="币种名称"
            name="name"
            :rules="[{ required: true, message: '请输入币种名称!' }]"
          >
            <a-input
              v-model:value="formState.name"
              placeholder="请输入币种名称"
            />
          </a-form-item>
          <a-form-item
            label="符号"
            name="symbol"
            :rules="[{ required: true, message: '请输入符号!' }]"
          >
            <a-input
              v-model:value="formState.symbol"
              placeholder="请输入符号"
            />
          </a-form-item>
        </a-form>
      </div>
    </a-modal>
  </div>
</template>
  
  
  <script setup>
import { reactive, ref, onBeforeMount } from "vue";
import * as currencyController from "@/services/api/currencyController";
import { message } from "ant-design-vue";
import { Modal } from "ant-design-vue";
import PaginationPage from "@/components/paginationPage.vue";

onBeforeMount(() => {
  pager.pageSize = 20;
  pager.pageNo = 1;
  getList();
});

const getPage = ({ page, pageSize }) => {
  pager.pageNo = page;
  pager.pageSize = pageSize;
  getList();
};
const list = ref([]);
const getList = () => {
  const { pageSize, pageNo } = pager;
  const params = {
    pageSize,
    pageNo,
  };
  currencyController.page(params).then((res) => {
    const { data } = res;
    let {
      records,
      pageSize,
      totalRow,
      totalPage: pages,
      pageNo
    } = data;
    pager.pageSize = pageSize;
    pager.pages = pages;
    pager.total = totalRow;
    pager.pageNo = pageNo;
    list.value = records;
  });
};
const cancelModal = () => {
  isShow.value = false;
  formState.symbol = "";
  formState.code = "";
  formState.name = "";
  formState.type = "add";
};
const toAdd = () => {
  isShow.value = true;
  formState.type = "add";
};
const isShow = ref(false);
const formState = reactive({});
const formRef = ref(null);
const handleOk = async () => {
  try {
    const values = await formRef.value.validateFields();
    if (values && formState.type == "add") {
      const params = {
        ...formState,
      };
      currencyController.save(params).then(() => {
        pager.pageSize = 20;
        pager.pageNo = 1;
        message.success("新增成功");
        cancelModal();
        getList();
      });
    }
    if (values && formState.type == "edit") {
      const params = {
        ...formState,
      };
      currencyController.update({code:formState.code},params).then(() => {
        pager.pageSize = 20;
        pager.pageNo = 1;
        message.success("编辑成功");
        cancelModal();
        getList();
      });
    }
  } catch (errorInfo) {
    console.log("Failed:", errorInfo);
  }
};

const editThis = (records) => {
  formState.code = records.code;
  formState.name = records.name;
  formState.symbol = records.symbol;
  formState.type = "edit";
  isShow.value = true;
};

const cancelThis = (records) => {
  Modal.confirm({
    title: "是否删除？",
    onOk() {
      currencyController.deleteUsingDelete({ code: records.code }).then(() => {
        message.success("删除成功");
        pager.pageSize = 20;
        pager.pageNo = 1;
        getList();
      });
    },
    onCancel() {
      console.log("Cancel");
    },
    class: "test",
  });
};
let pager = reactive({
  pageSize: 0,
  total: 0,
  pages: 0,
  pageNo: 0,
});
const columns = [
 
  {
    title: "代码",
    dataIndex: "code",
    key: "code",
  }, {
    title: "币种名称",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "符号",
    dataIndex: "symbol",
    key: "symbol",
  },{
    title: "创建时间",
    dataIndex: "createAt",
    key: "createAt",
  },
  {
    title: "操作",
    dataIndex: "action",
    key: "action",
  },
];
</script>
  
  
  <style scoped lang="less">
.ConfigcurrencyView {
  &-search {
    display: flex;
    justify-content: flex-end;
  }
  &-content {
    padding: 24px;
  }
}

.popBox {
  &-title {
    font-size: 18px;
    font-weight: 600;
  }
}
</style>